import { useEffect } from "react";

const useShowRecaptcha = (isActive) => {
  useEffect(() => {
    let element = document.querySelector(".grecaptcha-badge");
    if (element) {
      element.style.visibility = isActive ? "visible" : "hidden";
    }
  }, [isActive]);
};

export default useShowRecaptcha;
