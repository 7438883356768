import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import useShowRecaptcha from "./showRecaptcha";
import LanguageSwitcher from "./LanguageSwitcher";
import { StoreContextProvider } from "./Store";
import Steps from "./Steps";

import "./App.css";

const App: React.FC = () => {
  useShowRecaptcha(true);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t("home-meta-title");

    const reCaptchaScritpBlock = document.createElement("script");
    reCaptchaScritpBlock.id = "recaptcha-script";
    reCaptchaScritpBlock.src = `https://www.google.com/recaptcha/enterprise.js?render=${process.env.REACT_APP_RECAPTCHA_SITE_KEY}`;
    reCaptchaScritpBlock.async = true;
    document.head.appendChild(reCaptchaScritpBlock);
  }, [t]);

  useEffect(() => {
    const urlSegments = window.location.pathname.split("/");
    const language = i18n.resolvedLanguage;
    const locale = urlSegments[1];
    if (locale !== i18n.resolvedLanguage && locale !== language) {
      i18n.changeLanguage(locale);
    }
  }, [i18n]);

  return (
    <StoreContextProvider>
      <main className="app">
        <header>
          <LanguageSwitcher />
        </header>
        <Steps />
      </main>
    </StoreContextProvider>
  );
};

export default App;
