export interface State {
  eligible: boolean;
  name: string;
}

export interface States {
  [key: string]: State;
}

const states: States = {
  AL: {
    eligible: false,
    name: "Alabama",
  },
  AK: {
    eligible: false,
    name: "Alaska",
  },
  AZ: {
    eligible: false,
    name: "Arizona",
  },
  AR: {
    eligible: false,
    name: "Arkansas",
  },
  CA: {
    eligible: false,
    name: "California",
  },
  CO: {
    eligible: false,
    name: "Colorado",
  },
  CT: {
    eligible: false,
    name: "Connecticut",
  },
  DE: {
    eligible: false,
    name: "Delaware",
  },
  DC: {
    eligible: false,
    name: "District Of Columbia",
  },
  FL: {
    eligible: false,
    name: "Florida",
  },
  GA: {
    eligible: false,
    name: "Georgia",
  },
  HI: {
    eligible: false,
    name: "Hawaii",
  },
  ID: {
    eligible: false,
    name: "Idaho",
  },
  IL: {
    eligible: false,
    name: "Illinois",
  },
  IN: {
    eligible: false,
    name: "Indiana",
  },
  IA: {
    eligible: false,
    name: "Iowa",
  },
  KS: {
    eligible: false,
    name: "Kansas",
  },
  KY: {
    eligible: false,
    name: "Kentucky",
  },
  LA: {
    eligible: false,
    name: "Louisiana",
  },
  ME: {
    eligible: false,
    name: "Maine",
  },
  MD: {
    eligible: false,
    name: "Maryland",
  },
  MA: {
    eligible: false,
    name: "Massachusetts",
  },
  MI: {
    eligible: false,
    name: "Michigan",
  },
  MN: {
    eligible: false,
    name: "Minnesota",
  },
  MS: {
    eligible: false,
    name: "Mississippi",
  },
  MO: {
    eligible: false,
    name: "Missouri",
  },
  MT: {
    eligible: false,
    name: "Montana",
  },
  NE: {
    eligible: false,
    name: "Nebraska",
  },
  NV: {
    eligible: false,
    name: "Nevada",
  },
  NH: {
    eligible: false,
    name: "New Hampshire",
  },
  NJ: {
    eligible: false,
    name: "New Jersey",
  },
  NM: {
    eligible: false,
    name: "New Mexico",
  },
  NY: {
    eligible: false,
    name: "New York",
  },
  NC: {
    eligible: false,
    name: "North Carolina",
  },
  ND: {
    eligible: false,
    name: "North Dakota",
  },
  OH: {
    eligible: false,
    name: "Ohio",
  },
  OK: {
    eligible: false,
    name: "Oklahoma",
  },
  OR: {
    eligible: false,
    name: "Oregon",
  },
  PA: {
    eligible: false,
    name: "Pennsylvania",
  },
  PR: {
    eligible: false,
    name: "Puerto Rico",
  },
  RI: {
    eligible: false,
    name: "Rhode Island",
  },
  SC: {
    eligible: false,
    name: "South Carolina",
  },
  SD: {
    eligible: false,
    name: "South Dakota",
  },
  TN: {
    eligible: false,
    name: "Tennessee",
  },
  TX: {
    eligible: false,
    name: "Texas",
  },
  UT: {
    eligible: false,
    name: "Utah",
  },
  VT: {
    eligible: false,
    name: "Vermont",
  },
  VA: {
    eligible: false,
    name: "Virginia",
  },
  WA: {
    eligible: true,
    name: "Washington",
  },
  WV: {
    eligible: false,
    name: "West Virginia",
  },
  WI: {
    eligible: true,
    name: "Wisconsin",
  },
  WY: {
    eligible: false,
    name: "Wyoming",
  },
};

export default states;
