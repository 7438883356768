import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

interface Languages {
  [key: string]: string;
}

interface Resources {
  [key: string]: {
    translations: string;
  };
}

export const languages: Languages = {
  English: "en",
  Spanish: "es",
};

export const languageNames: Languages = {
  [languages.English]: "English",
  [languages.Spanish]: "Español",
};

let resources: Resources = {};
const whitelist = [];

whitelist.push(...Object.values(languages));
whitelist.forEach(
  (languageCode) =>
    (resources[languageCode] = {
      translations: require(`./locales/${languageCode}.json`),
    })
);

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init(
    {
      fallbackLng: "en",
      // debug: true,
      detection: {
        order: [
          "path",
          "querystring",
          "cookie",
          "localStorage",
          "sessionStorage",
          "navigator",
          "htmlTag",
        ],
        caches: [],
        lookupFromPathIndex: 1,
      },
      ns: ["translations"],
      defaultNS: "translations",
      interpolation: {
        escapeValue: false,
        formatSeparator: ".",
      },
      resources,
      react: {
        useSuspense: false,
      },
    },
    (err, t) => {
      if (err) console.error("Error Initializing i18next");
    }
  );

export default i18n;
