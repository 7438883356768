import React from "react";
import { useStoreContext, SubmissionStates } from "./Store";
import Step01 from "./Step01";
import Step02 from "./Step02";
import Step03 from "./Step03";
import Step04 from "./Step04";
import StepFinal from "./StepFinal";

const Steps: React.FC = () => {
  const { submissionState } = useStoreContext();

  return (
    <section>
      {submissionState === SubmissionStates.STEP_01 ? <Step01 /> : null}
      {submissionState === SubmissionStates.STEP_02 ? <Step02 /> : null}
      {submissionState === SubmissionStates.STEP_03 ? <Step03 /> : null}
      {submissionState === SubmissionStates.STEP_04 ? <Step04 /> : null}
      {submissionState === SubmissionStates.STEP_FINAL ? <StepFinal /> : null}
    </section>
  );
};

export default Steps;
