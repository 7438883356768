import React, { createContext, useEffect, useState } from "react";
import PropTypes from "prop-types";

import { Address, ContactInfo } from "./types";
import { useTranslation } from "react-i18next";
import { type Token } from "@careevolution/mydatahelps-js";

const SUBMIT_REQUEST_URL = `${process.env.REACT_APP_API_BASE_URL}/orders/submitorder`;
const VALIDATE_REQUEST_URL = `${process.env.REACT_APP_API_BASE_URL}/orders/validateorder`;
const CREATE_PARTICIPANT_URL = `${process.env.REACT_APP_API_BASE_URL}/create-participant`;

async function generateCaptchaToken(action: string): Promise<string> {
  return new Promise((resolve, reject) => {
    window.grecaptcha.enterprise.ready(async () => {
      let token = await window.grecaptcha.enterprise.execute(
        process.env.REACT_APP_RECAPTCHA_SITE_KEY,
        { action }
      );
      resolve(token);
    });
  });
}

export const SubmissionStates = {
  // The initial step, where the user enters their address
  STEP_01: "step01",
  // ==== We call the address validation API  ====
  // The address validation step
  STEP_02: "step02",
  // The product selection step
  STEP_03: "step03",
  // ==== The order is submitted ====
  // ==== A participant id is created ====
  // The optional questions, from the MDH survey
  STEP_04: "step04",
  // The final step, you're done!
  STEP_FINAL: "final",
};

export const ProductSKU = {
  NASAL: "NSL_NASAL",
  INJECTABLE: "NSL_INTRAMUSCULAR",
};

export type StoreContextType = {
  submissionState: string;
  submittedAddress: Address | null;
  validatedAddress: Address | null;
  contactInfo: ContactInfo | null;
  orderID: string;
  participantAPIAccessToken: Token | null;
  getProjectZipCodes: () => Promise<string[]>;
  validateAddress: (address: Address) => Promise<any>;
  onSubmitAddress: (response: {
    submittedAddress: Address;
    validatedAddress: Address;
    contactInfo: ContactInfo;
  }) => void;
  onSubmitOrder: (sku: string) => Promise<any>;
  onClickCorrect: () => void;
  onClickIncorrect: () => void;
  onFinishSurvey: () => void;
};

const StoreContext = createContext<StoreContextType>({
  submissionState: SubmissionStates.STEP_01,
  submittedAddress: null,
  validatedAddress: null,
  contactInfo: null,
  orderID: "",
  participantAPIAccessToken: null,
  getProjectZipCodes: async () => [],
  validateAddress: async () => {},
  onSubmitAddress: () => {},
  onSubmitOrder: async () => {},
  onClickCorrect: () => {},
  onClickIncorrect: () => {},
  onFinishSurvey: () => {},
});

type StoreContextProviderProps = {
  children: React.ReactNode;
};

export function StoreContextProvider({ children }: StoreContextProviderProps) {
  const { t } = useTranslation();
  const [submissionState, setSubmissionState] = useState(
    SubmissionStates.STEP_01
  );
  const [projectZipCodes, setProjectZipCodes] = useState<string[]>([]);
  const [contactInfo, setContactInfo] = useState<ContactInfo | null>(null);
  const [submittedAddress, setSubmittedAddress] = useState<Address | null>(
    null
  );
  const [validatedAddress, setValidatedAddress] = useState<Address | null>(
    null
  );
  const [participantAPIAccessToken, setParticipantAPIAccessToken] =
    useState<Token | null>(null);
  const [orderID, setOrderID] = useState<string>("");

  const changeSubmissionState = (state: string) => {
    window.location.hash = state;
    setSubmissionState(state);
  };

  // Watch for changes to the hash and allow the user to navigate back to previous steps
  useEffect(() => {
    const onChangeHash = () => {
      // Check if the hash is a valid state
      const state = window.location.hash.slice(1);
      if (Object.values(SubmissionStates).includes(state)) {
        setSubmissionState(state);
      }
    };

    window.addEventListener("hashchange", onChangeHash);
    return () => {
      window.removeEventListener("hashchange", onChangeHash);
    };
  }, [setSubmissionState]);

  const getFetchHeaders = async (hasCaptcha = true) => {
    const headers: HeadersInit = {
      "Content-Type": "application/json",
      "X-Api-Key": process.env.REACT_APP_API_KEY,
    };

    if (hasCaptcha) {
      let recaptchaToken;
      try {
        recaptchaToken = await generateCaptchaToken("submitOrder");
      } catch (e) {
        console.error("Error generating recaptcha token", e);
        return;
      }

      headers["X-Captcha-Token"] = recaptchaToken;
    }

    return headers;
  };

  const getProjectZipCodes = async () => {
    if (projectZipCodes.length) {
      return projectZipCodes;
    }
    try {
      const response = await fetch(
        "https://configuration-assets.syctnational.careevolutionapps.dev/nsl2/sites.json"
      );
      const data = await response.json();
      const zipCodes = Object.keys(data.zips);
      setProjectZipCodes(zipCodes);
      return zipCodes;
    } catch (error) {
      console.error("Error fetching project zip codes", error);
      return [];
    }
  };

  const validateAddress = async (address: Address) => {
    let response;
    let errorText;
    try {
      response = await fetch(VALIDATE_REQUEST_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "x-api-key": process.env.REACT_APP_API_KEY,
        },
        body: JSON.stringify(address),
      });
      response = await response.json();
    } catch (e) {
      errorText = t("something-went-wrong");
      return { isValid: false, errorText };
    }
    if (!response.isValid) {
      if (response.type === "invalid-address") {
        errorText = t("invalid-address");
        return { isValid: false, errorText };
      } else if (response.type === "phone-address-taken") {
        errorText = t("address-taken");
        return { isValid: false, errorText };
      } else if (response.type === "all-kits-taken") {
        errorText = t("all-kits-taken");
        return { isValid: false, errorText };
      } else if (["postal-code", "dtc-unavailable"].includes(response.type)) {
        errorText = t("not-availale");
        return { isValid: false, errorText };
      }
    }

    return { isValid: true, validatedAddress: response.address as Address };
  };

  /**
   *
   * @param response The response from the address validation step
   */
  const onSubmitAddress = (response: {
    submittedAddress: Address;
    validatedAddress: Address;
    contactInfo: ContactInfo;
  }) => {
    setContactInfo(response.contactInfo);
    setSubmittedAddress(response.submittedAddress);
    setValidatedAddress(response.validatedAddress);
    changeSubmissionState(SubmissionStates.STEP_02);
  };

  const onClickIncorrect = () => {
    changeSubmissionState(SubmissionStates.STEP_01);
  };

  const onClickCorrect = async () => {
    changeSubmissionState(SubmissionStates.STEP_03);
  };

  /**
   * This step submits the order to the API
   *
   * @param sku The product SKU to submit
   */
  const onSubmitOrder = async (sku: string) => {
    const submitHeaders = await getFetchHeaders();
    const body = {
      address: {
        ...validatedAddress,
      },
      sku,
      project: submittedAddress?.project,
      contactInfo,
    };
    let newOrderID;
    try {
      let response = await fetch(SUBMIT_REQUEST_URL, {
        method: "POST",
        headers: submitHeaders,
        body: JSON.stringify(body),
      });
      const jsonResponse = await response.json();
      newOrderID = jsonResponse.orderID;
      setOrderID(newOrderID);
    } catch (e) {
      console.error("Error submitting order", e);
      return t("something-went-wrong");
    }

    const createHeaders = await getFetchHeaders(false);

    const participantBody = {
      projectCode: submittedAddress?.project,
      orderId: newOrderID,
    };

    try {
      let response = await fetch(CREATE_PARTICIPANT_URL, {
        method: "POST",
        headers: createHeaders,
        body: JSON.stringify(participantBody),
      });
      const jsonResponse = await response.json();
      const newAccessToken = jsonResponse.delegatedAccessToken;
      setParticipantAPIAccessToken(newAccessToken);
    } catch (e) {
      console.error("Error creating participant", e);
      return t("something-went-wrong");
    }

    changeSubmissionState(SubmissionStates.STEP_04);
  };

  /**
   * This step finishes the survey
   */
  const onFinishSurvey = () => {
    changeSubmissionState(SubmissionStates.STEP_FINAL);
  };

  const store: StoreContextType = {
    submissionState,
    submittedAddress,
    validatedAddress,
    contactInfo,
    orderID,
    participantAPIAccessToken,
    getProjectZipCodes,
    validateAddress,
    onSubmitAddress,
    onSubmitOrder,
    onClickCorrect,
    onClickIncorrect,
    onFinishSurvey,
  };

  return (
    <StoreContext.Provider value={store}>{children}</StoreContext.Provider>
  );
}

StoreContextProvider.propTypes = {
  children: PropTypes.node,
};

export const useStoreContext = () => React.useContext(StoreContext);
