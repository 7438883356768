import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faSpinner } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import states from "./states";
import { Address } from "./types";
import { useStoreContext } from "./Store";

const Step01: React.FC = () => {
  const { t, i18n } = useTranslation();
  const {
    submittedAddress,
    contactInfo,
    getProjectZipCodes,
    validateAddress,
    onSubmitAddress,
  } = useStoreContext();

  const [name, setName] = useState(contactInfo?.name || t("resident") || "");
  const [email, setEmail] = useState(contactInfo?.email || "");
  const [address1, setAddress1] = useState(submittedAddress?.address1 || "");
  const [address2, setAddress2] = useState(submittedAddress?.address2 || "");
  const [city, setCity] = useState(submittedAddress?.city || "");
  const [state, setState] = useState(submittedAddress?.state || "WA");
  const [zip5, setZip5] = useState(submittedAddress?.zip5 || "");
  const [error, setError] = useState("");
  const [fieldErrors, setFieldError] = useState<string[]>([]);
  const [isValidating, setIsValidating] = useState(false);

  const onValidateAddress = async () => {
    setIsValidating(true);

    const kingCountyZipCodes = await getProjectZipCodes();

    const project = zip5 && kingCountyZipCodes.includes(zip5) ? "NSL2" : "NSL1";

    const toValidateAddress: Address = {
      address1,
      address2,
      city,
      state,
      zip5,
      project,
    };
    let errorText;
    const newFieldErrors: string[] = [];
    if (!email || /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.(gov|org)$/.test(email)) {
      newFieldErrors.push("email");
    }
    if (!address1) {
      newFieldErrors.push("address1");
    }
    if (!city) {
      newFieldErrors.push("city");
    }
    if (!state || state !== "WA") {
      newFieldErrors.push("state");
    }
    if (!zip5 || !/^\d{5}(?:[-\s]\d{4})?$/.test(zip5)) {
      newFieldErrors.push("zip5");
    }

    if (newFieldErrors.length || errorText) {
      setFieldError(newFieldErrors);
      setIsValidating(false);
      return { isValid: false, errorText };
    }

    try {
      const { isValid, validatedAddress, errorText } = await validateAddress(
        toValidateAddress
      );
      setIsValidating(false);
      return {
        isValid,
        submittedAddress: toValidateAddress,
        validatedAddress,
        errorText,
      };
    } catch (e) {
      setIsValidating(false);
      return { isValid: false, errorText: t("something-went-wrong") };
    }
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();

    let isValid, submittedAddress, validatedAddress, errorText;
    try {
      ({ isValid, submittedAddress, validatedAddress, errorText } =
        await onValidateAddress());
    } catch (e) {
      isValid = false;
      errorText = t("something-went-wrong");
    }

    if (isValid && submittedAddress && validatedAddress) {
      onSubmitAddress({
        submittedAddress,
        validatedAddress,
        contactInfo: {
          name: name || t("resident"),
          language: i18n.resolvedLanguage,
          email,
        },
      });
    }

    if (!isValid) {
      if (!errorText) {
        errorText = t("something-went-wrong");
      }
      setError(errorText);
    }
  };

  const onChangeName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setName(event.target.value);
  };

  const onChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
    if (fieldErrors.includes("email")) {
      setFieldError(fieldErrors.filter((field) => field !== "email"));
    }
  };

  const onChangeAddress1 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress1(event.target.value);
    if (fieldErrors.includes("address1")) {
      setFieldError(fieldErrors.filter((field) => field !== "address1"));
    }
  };
  const onChangeAddress2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAddress2(event.target.value);
  };
  const onChangeCity = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCity(event.target.value);
    if (fieldErrors.includes("city")) {
      setFieldError(fieldErrors.filter((field) => field !== "city"));
    }
  };
  const onChangeState = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setState(event.target.value);
    if (fieldErrors.includes("state")) {
      setFieldError(fieldErrors.filter((field) => field !== "state"));
    }
  };
  const onChangeZip5 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZip5(event.target.value);
    if (fieldErrors.includes("zip5")) {
      setFieldError(fieldErrors.filter((field) => field !== "zip5"));
    }
  };

  return (
    <>
      <h1>{t("page-header")}</h1>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="name">{t("name")}</label>
          <input id="name" type="text" value={name} onChange={onChangeName} />
        </div>
        <div
          className={`form-group ${
            fieldErrors.includes("email") ? "error" : ""
          }`}
        >
          <label htmlFor="email">{t("email")}</label>
          <input
            id="email"
            type="email"
            value={email}
            onChange={onChangeEmail}
            aria-errormessage="email-val-err"
          />
          {fieldErrors.includes("email") ? (
            <p className="form-error field-error" id="email-val-err">
              {t("email-error")}
            </p>
          ) : null}
        </div>
        <div
          className={`form-group ${
            fieldErrors.includes("address1") ? "error" : ""
          }`}
        >
          <label htmlFor="address1">{t("street-address")}</label>
          <input
            id="address1"
            type="text"
            value={address1}
            onChange={onChangeAddress1}
            autoComplete="address-line1"
          />
          <input
            id="address2"
            type="text"
            value={address2}
            onChange={onChangeAddress2}
            autoComplete="address-line1"
          />
        </div>
        <div
          className={`form-group ${
            fieldErrors.includes("city") ? "error" : ""
          }`}
        >
          <label htmlFor="city">{t("city")}</label>
          <input
            id="city"
            type="text"
            value={city}
            onChange={onChangeCity}
            autoComplete="address-level2"
          />
        </div>
        <div
          className={`form-group ${
            fieldErrors.includes("state") ? "error" : ""
          }`}
        >
          <label htmlFor="state">{t("state")}</label>
          <select
            value={state}
            id="state"
            onChange={onChangeState}
            autoComplete="address-level1"
            aria-errormessage="state-val-err"
          >
            <option>---</option>
            {Object.keys(states).map((state: string) => (
              <option key={state} value={state}>
                {states[state].name}
              </option>
            ))}
          </select>
          {fieldErrors.includes("state") ? (
            <p className="form-error field-error" id="state-val-err">
              {t("state-error")}
            </p>
          ) : null}
        </div>
        <div
          className={`form-group ${
            fieldErrors.includes("zip5") ? "error" : ""
          }`}
        >
          <label htmlFor="zip5">{t("zip-code")}</label>
          <input
            id="zip5"
            type="text"
            value={zip5}
            onChange={onChangeZip5}
            pattern="[0-9]*"
          />
        </div>
        <button
          type="submit"
          disabled={!address1 || !city || !zip5 || !state || isValidating}
          className={isValidating ? "in-progress" : ""}
        >
          {t("submit")}
          <FontAwesomeIcon icon={isValidating ? faSpinner : faAnglesRight} />
        </button>
        {error ? <p className="form-error">{error}</p> : null}
      </form>
      <p className="disclaimer">{t("disclaimer")}</p>
    </>
  );
};

export default Step01;
