import React from "react";
import { useTranslation } from "react-i18next";

import { faGlobe } from "@fortawesome/free-solid-svg-icons";
import { languages, languageNames } from "./i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();

  // If none specified, return english
  const locale = i18n.resolvedLanguage;
  const nextLanguage =
    locale === languages.English ? languages.Spanish : languages.English;

  const handleTranslation = async () => {
    if (nextLanguage !== i18n.resolvedLanguage) {
      await i18n.changeLanguage(nextLanguage);
    }
  };

  const nextLanguageLocaleName = languageNames[nextLanguage];

  return (
    <button className="language-switcher" onClick={handleTranslation}>
      {nextLanguageLocaleName}
      <FontAwesomeIcon icon={faGlobe} />
    </button>
  );
};

export default LanguageSwitcher;
