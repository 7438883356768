import React, { useEffect } from "react";
import MyDataHelps from "@careevolution/mydatahelps-js";
import { useStoreContext } from "./Store";

const Step04: React.FC = () => {
  const { participantAPIAccessToken, onFinishSurvey } = useStoreContext();

  useEffect(() => {
    if (!participantAPIAccessToken) {
      return;
    }
    MyDataHelps.setParticipantAccessToken(
      participantAPIAccessToken,
      process.env.REACT_APP_MYDATAHELPS_API_URL || ""
    );
    MyDataHelps.startSurvey("DemographicQuestions");

    const surveyCompleteHandler = (message: MessageEvent) => {
      if (message.data.name === "SurveyFinished") {
        onFinishSurvey();
      }
    };

    window.addEventListener("message", surveyCompleteHandler);
    return () => {
      window.removeEventListener("message", surveyCompleteHandler);
    };
  }, [participantAPIAccessToken, onFinishSurvey]);

  return <></>;
};

export default Step04;
