import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAnglesRight, faX } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useStoreContext } from "./Store";

const Step02: React.FC = () => {
  const { t } = useTranslation();
  const {
    submittedAddress,
    validatedAddress,
    onClickIncorrect,
    onClickCorrect,
  } = useStoreContext();

  return (
    <>
      <h2>{t("confirm-address")}</h2>
      <p>{t("confirm-address-subheader")}</p>

      <h4 className="address-label">{t("your-address")}</h4>
      <address>
        {submittedAddress?.address1}
        <br />
        {submittedAddress?.address2}
        <br />
        {submittedAddress?.city}, {submittedAddress?.state}{" "}
        {submittedAddress?.zip5}
        {submittedAddress?.zip4 && `-${submittedAddress?.zip4}`}
      </address>
      <h4 className="address-label">{t("validated-address")}</h4>
      <address>
        {validatedAddress?.address1}
        <br />
        {validatedAddress?.address2}
        <br />
        {validatedAddress?.city}, {validatedAddress?.state}{" "}
        {validatedAddress?.zip5}
      </address>
      <div className="button-group">
        <button className="incorrect" onClick={onClickIncorrect}>
          {t("no-incorrect")}
          <FontAwesomeIcon icon={faX} />
        </button>
        <button className={`correct`} onClick={onClickCorrect}>
          {t("yes-submit")}
          <FontAwesomeIcon icon={faAnglesRight} />
        </button>
      </div>
    </>
  );
};

export default Step02;
