import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRedo, faShippingFast } from "@fortawesome/free-solid-svg-icons";
import { useStoreContext } from "./Store";

const StepFinal: React.FC = () => {
  const { t } = useTranslation();
  const { orderID } = useStoreContext();

  const onReload = () => {
    window.location.reload();
  };

  return (
    <div className="step-final">
      <FontAwesomeIcon icon={faShippingFast} size="3x" />
      <h1>{t("order-complete")}</h1>
      <p>{t("order-complete-subheader")}</p>
      <p>
        {t("order-id")} <em>{orderID}</em>
      </p>
      <button onClick={onReload}>
        {t("order-another")}
        <FontAwesomeIcon icon={faRedo} />
      </button>
    </div>
  );
};

export default StepFinal;
