import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAnglesRight,
  faSquare,
  faCheckSquare,
  faSpinner,
  faCheck,
} from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";
import { useStoreContext, ProductSKU } from "./Store";

const Step03: React.FC = () => {
  const { t } = useTranslation();
  const [productSKU, setProductSKU] = useState("");
  const [isConfirmPersonal, setIsConfirmPersonal] = useState(false);
  const [isConfirmResell, setIsConfirmResell] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { onSubmitOrder } = useStoreContext();

  const handleProductSKU = (sku: string) => {
    setProductSKU(sku);
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    if (!productSKU || !isConfirmPersonal || !isConfirmResell || isSubmitting) {
      return;
    }

    setIsSubmitting(true);
    try {
      await onSubmitOrder(productSKU);
    } catch (error) {
      console.error(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const onConfirmPersonal = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsConfirmPersonal(event.target.checked);
  };
  const onConfirmResell = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsConfirmResell(event.target.checked);
  };

  return (
    <form onSubmit={handleSubmit}>
      <h1>{t("product-header")}</h1>
      <div className="product-buttons">
        <button
          className={`product-button ${
            productSKU === ProductSKU.INJECTABLE ? "selected" : ""
          }`}
          onClick={() => handleProductSKU(ProductSKU.INJECTABLE)}
          type="button"
          role="radio"
          aria-checked={productSKU === ProductSKU.INJECTABLE}
          aria-keyshortcuts="Space Enter"
        >
          <div>
            <span>{t("injectable")}</span>
            <small>{t("injectable-doses")}</small>
          </div>
          <FontAwesomeIcon
            icon={
              productSKU === ProductSKU.INJECTABLE ? faCheckSquare : faSquare
            }
            aria-hidden
          />
        </button>
        <button
          className={`product-button ${
            productSKU === ProductSKU.NASAL ? "selected" : ""
          }`}
          onClick={() => handleProductSKU(ProductSKU.NASAL)}
          type="button"
          role="radio"
          aria-checked={productSKU === ProductSKU.NASAL}
          aria-keyshortcuts="Space Enter"
        >
          <div>
            <span>{t("nasal-spray")}</span>
            <small>{t("nasal-spray-doses")}</small>
          </div>
          <FontAwesomeIcon
            icon={productSKU === ProductSKU.NASAL ? faCheckSquare : faSquare}
            aria-hidden
          />
        </button>
      </div>
      <div className="checkbox-container">
        <input
          id="confirm-personal"
          type="checkbox"
          onChange={onConfirmPersonal}
        />
        <label htmlFor="confirm-personal">{t("confirm-personal")}</label>
        {isConfirmPersonal && (
          <FontAwesomeIcon aria-hidden icon={faCheck} size="xl" />
        )}
      </div>
      <div className="checkbox-container">
        <input id="confirm-resell" type="checkbox" onChange={onConfirmResell} />
        <label htmlFor="confirm-resell">{t("confirm-resell")}</label>
        {isConfirmResell && (
          <FontAwesomeIcon aria-hidden icon={faCheck} size="xl" />
        )}
      </div>
      <button
        type="submit"
        disabled={
          !productSKU || !isConfirmPersonal || !isConfirmResell || isSubmitting
        }
        className={isSubmitting ? "in-progress" : ""}
      >
        {t("submit")}
        <FontAwesomeIcon icon={isSubmitting ? faSpinner : faAnglesRight} />
      </button>
    </form>
  );
};

export default Step03;
